
import { Vue, Component } from 'vue-property-decorator';
import moment from "moment";
import { Projects } from "@/pages/project/summary/summary-interface";
import { USER_LEVEL, PROJECT_STATUS } from "@/enum/enums";

interface Response {
  channel: string,
  group: string,
  status: PROJECT_STATUS,
  surveyId: string,
  surveyNo: number,
  partnerSid: string,
  startDate: string,
  endDate: string,
  title: string,
  LOI: number,
  panel: string,
  completed: number,
  screened: number,
  quotaOut: number,
  drop: number,
  total: number,
  QualifiedFee: number,
  terminateFee: number,
  quotaFullFee: number,
  reqC: number,
}

@Component
export default class AdminBuyer extends Vue {
  loading = false;
  startDate = moment().startOf('month').format('YYYY-MM-DD');
  endDate = moment().format('YYYY-MM-DD');
  buyer = '';

  term = 'day';
  show = {
    calendar: false,
    pie: true,
    line: true,
    table: true,
  };

  projects: Projects[] = [];

  created() {
    (async() => {
      await this.load();
    })()
  }

  async load() {
    try {
      const { result, data, message } = await this.axios.get(`/project`)

      if (result) {
        console.log(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async csvDown() {
    return 'a';
  }

  get startDateMin() {
    return moment(this.endDateMax).add(-1, 'month').format('YYYY-MM-DD');
  }

  get endDateMax() {
    return moment().format('YYYY-MM-DD');
  }

  get isAdmin() {
    const { GENERAL_ADMIN, SUPER_ADMIN } = USER_LEVEL
    return [GENERAL_ADMIN, SUPER_ADMIN].includes(this.getUserLevel)
  }
}
